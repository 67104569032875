<template>
    <section>
        <div class="row">
            <div class="
          col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
          d-flex
          mb-3
        ">
                <div class="table-box p-3">

                    <data-table id="example-data-table"
                    :translations="translations"
                        :options="options"
                        :data-set="data.dataList"
                        :page-size="data.pageSize"
                        @change-action="changeAction"
                        @change-status="changeStatus"
                        @pagination="pagination"
                        @sorting="sorting"
                        @limit-change="limitChange"/>

                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import HelperFunction from '@/common/helpers';
    import DataTable from "@/common/components/datatable";
    import {useRoute} from 'vue-router';
    import {useStore} from 'vuex';
    import {onMounted, computed} from 'vue';

    export default {
        name: "ProjectDocuments",
        components: {
            DataTable
        },
        setup() {
            const {data, getAllData, editFormData, deleteListData, storeFormData, permissionResolver} = HelperFunction();
            const store = useStore();
            const company = store.getters.getSelectedBoard;
            const route = useRoute();
            let setParams = {
                company_id: company.id,
                project_id: route.params.id,
                sort: 'desc'
            };

            data.setURL = vueConfig.Project.DocumentEndPoint;

            onMounted(async () => {
                getAllData(data.setURL, setParams);
            });

            /** watch **/
            permissionResolver('documents');
            /** watch end **/

            const translations = computed(() => {
                return store.state.translations;  
            });

            // SET DATATABLE PROPS
            let options = {
                name: translations.value?.project_document_list || 'Project Document List',
                columns: [
                    {
                        title: translations.value?.document_id || 'Document ID',
                        type: 'text',
                        key: 'project_document_id',
                        sort: false
                    },
                    {
                        title: translations.value?.document || 'Document',
                        type: 'link',
                        key: 'document_name',
                        sort: false,
                        truncate: true,
                        modifier: (obj, row) => {
                            return `/projects/${setParams.project_id}/document/${row.id}/view`;
                        }
                    },
                    {
                        title: translations.value?.version || 'Version',
                        type: 'text',
                        key: 'document_version',
                        sort: true
                    },
                    {
                        title: translations.value?.author || 'Author',
                        type: 'text',
                        key: 'author',
                        sort: false
                    },
                    {
                        title: translations.value?.status || 'Status',
                        type: 'html',
                        key: 'document_status',
                        modifier: (value, row) => {
                            const _width = (row.done_items_count / row.items_count) * 100
                            const html = `<div class="progress bg-warning"><div class="progress-bar bg-success" role="progressbar" style="width:${_width}%" aria-valuenow="${_width}" aria-valuemin="0" aria-valuemax="100"></div></div>`
                            return html;
                        }
                    },
                    {
                        title: translations.value?.deadline || 'Deadline',
                        type: 'text',
                        key: 'deadline',
                        sort: false
                    },
                ],
                showAction: false,
                action: [
                    {
                        title: 'Edit',
                        icon: 'edit',
                        type: 'edit',
                        show: true
                    },
                    {
                        title: 'Delete',
                        icon: 'trash-alt',
                        type: 'delete',
                        show: true
                    }
                ]
            }

            async function changeAction(action, row) {
                if (action.type === 'edit') {
                    await editFormData({url: `${data.setURL}/${row.id}`, modalId: 'showModal'}, false, setFormData)
                }
                if (action.type === 'delete') {
                    deleteListData(`${data.setURL}/${row.id}`, setParams)
                }
            }

            const changeStatus = (column, row) => {
                let input = {}
                if (row.id) {
                    input._method = 'PUT'
                }
                storeFormData({url: `${vueConfig.Project.ChangeStatusEndPoint}/${row.id}`}, input, false, setParams)
            }
            const pagination = (page) => {
                setParams.page = page;
                getAllData(data.setURL, setParams);
            }
            const limitChange = (limit) => {
                setParams.limit = limit;
                delete setParams.page
                getAllData(data.setURL, setParams);
            }
            const sorting = (column) => {
                setParams.column = column.key;
                setParams.sort = setParams.sort == 'asc' ? 'desc' : 'asc';
                getAllData(data.setURL, setParams);
            }

            function setFormData(formData) {
                data.inputFormData.project_id = formData.project_id ? formData.project_id : ''
            }

            return {
                data, options, changeAction, changeStatus, pagination, limitChange, sorting, setParams, translations
            }
        }
    };
</script>

<style scoped>

</style>